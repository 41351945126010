import React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Card, Col, Row, Accordion} from "react-bootstrap";
import notfall from "../files/Notfallplan_Krisensituationen.pdf"
import krisen from "../files/Krisen-_und_Notfallplan.pdf"

export default class LinksPage extends React.Component {
    constructor(props) {
        super(props)

    }


    render() {

        const links = [
            {
                "name": "Bei Lebensgefahr:",
                "links": ["https://www.nora-notruf.de"]

            },
            {
                "name": "Giftnotzentrale:",
                "links": ["https://www.giz-nord.de"]
            },
            {
                "name": "Bundesweiter ärztlicher Notdienst und Terminservice bei der Suche eines Haus-, Fach- sowie Kinder- oder Jugendarztes:",
                "links": ["https://www.116117.de"]
            },
            {
                "name": "Notdienst der Apotheken:",
                "links": ["https://www.aponet.de"]
            },
            {
                "name": "Akute kindermedizinische Fragen:",
                "links":  ["https://www.kindergesundheit-info.de"]

            },
            {
                "name": "Wegweiser bei psychischen Krisen in Ostholstein:",
                "links": [notfall],
                "filename": "Notfallplan Krisensituationen"
            },
            {
                "name": "Persönlicher Krisen- und Notfallplan Kinder & Jugendliche:",
                "links": [krisen],
                "filename": "Krisen- und Notfallplan"

            },
            {
                "name": "Leitlinien für die ärztliche und psychotherapeutische Diagnostik und Therapie:",
                "links": ["https://www.awmf.org"]
            },
            {
                "name": "Informationen zum Corona-Virus & Impfungen:",
                "links": ["https://www.rki.de","https://www.impfen-sh.de"]

            },
            {
                "name": "Informationen zu Medikamenten:",
                "links": ["https://www.gelbe-liste.de"]
            },
            {
                "name": "Krankengymnastische Übungen:",
                "links": ["https://www.liebscher-bracht.com"]

            }
        ]
        return (
            <Layout>
                <SEO title="Links"/>

                <section className="page-section" id="services">
                    <div className="container">
                        <h1 className="text-center mt-0">Sinnvolle Internetquellen</h1>
                        <hr className="divider my-4"/>
                        <div className="row justify-content-center">
                            <p className={"text-center"}>
                                Wir werden  oft gefragt, welche Internetquellen sinnvoll und zuverlässig sind. Daher haben wir hier die wichtigsten verlinkt:
                            </p>
                        </div>

                            {links.map((link, idx)=>{
                              return <>
                              <Row className={"mt-3"}>
                                  <Col  xs={12}>
                                     <strong>{link.name}</strong><br/>
                                      {link.links.map((href, idx)=>{
                                          return <><a target={"_blank"} href={href}>{link.filename?link.filename:href}</a><br/></>
                                      })}
                                  </Col>
                              </Row>
                              </>
                            })}
                    </div>
                </section>


            </Layout>
        )
    }
}

